<template>
	<div class="card">
		<div class="card-header">
			<div class="d-flex align-items-center justify-content-between">
				<h3 class="card-title mb-0">
					Marcas
				</h3>
				<div>
					<button type="button" class="btn btn-link mr-2" v-on:click="getMarcas()">
						<span class="material-icons align-bottom">refresh</span>
					</button>
					<router-link class="btn btn-primary font-weight-bold" :to="{name:'admin.marcas.create'}">
						<span class="material-icons align-bottom">add_circle_outline</span>
						Agregar marca
					</router-link>
				</div>
			</div>
		</div>

		<div class="card-body text-center" v-if="loadingMarca">
			<div class="spinner-grow text-primary" role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</div>
		<div class="card-body" v-else>
			<div class="table-responsive" v-if="marcas.length>0">
				<table class="table table-sm">
					<thead>
						<tr>
							<th class="text-center" width="50">Código</th>
							<th class="text-center">Descripción</th>
							<th class="text-center" width="100">Estado</th>
							<th class="text-center"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(marca, index) in marcasFilters" :key="index">
							<th>{{marca.idMarca}}</th>
							<td>{{marca.descripcion}}</td>
							<td class="text-center">
								<span class="badge badge-info" v-if="marca.estado == 1">
									Activado
								</span>
								<span class="badge badge-danger" v-else>
									Desactivado
								</span>
							</td>
							<td class="text-right" width="158">
								<router-link class="btn btn-link btn-sm" :to="{name:'admin.marcas.edit', params: {id: marca.idMarca}}" title="Editar">
									<span class="material-icons align-bottom">edit</span>
								</router-link>
								<deleteMarca :id="marca.idMarca" @getMarcas="getMarcas()" />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-else>
				<div class="text-center py-5" v-if="marcas.length <= 0">
					<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
						<img v-lazy="imgSearch" class="img-fluid">
					</div>
					<h5>
					Aún no existen <strong>marcas</strong>.
					</h5>
				</div>
				<div class="text-center py-5" v-else>
					<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
						<img v-lazy="imgSearch" class="img-fluid">
					</div>
					<h5>
					No se encontraron resultados en la búsqueda.
					</h5>
				</div>
			</div>
			<div class="py-2 d-flex justify-content-center">
				<Paginacion :pagination="pagination" @paginate="changePagination" :offset="4" />
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	import deleteMarca from "@/views/admin/marcas/Delete.vue"

	const Paginacion = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Pagination" */"@/components/Pagination.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			deleteMarca,
			Paginacion
		},
		data(){
			return {
				imgSearch: require('@/assets/image/search_dark.webp'),
				pagination: {
					per_page: 10,
		            current_page: 1,
		            total: 50
		        },
			}
		},

		methods: {
			getMarcas(){
				this.$store.dispatch('getMarcas', {
					order: 'idMarca desc'
				})
			},
			changePagination(page){
				this.pagination.current_page = page
			},
		},
		computed: {
			marcas(){
				return this.$store.getters.marcas || []
			},
			marcasFilters(){
				var ini = ((this.pagination.current_page*this.pagination.per_page)-this.pagination.per_page)
				var fin = ((this.pagination.current_page*this.pagination.per_page))
				return this.marcas.slice(ini, fin)
			},
			loadingMarca(){
				return this.$store.getters.loading
			},
			url(){
				return window.url
			}
		},
		watch:{
			marcas: {
				handler(val){
					this.pagination.total = (val.length || 0)
				},
				deep: true
			}
		}
	}
</script>