<template>
	<div class="card">
		<div class="card-header">
			<div class="d-flex align-items-center justify-content-between">
				<h3 class="card-title mb-0">
					Modelos
				</h3>
				<div class="d-flex">
					<div class="mr-2">
						<Marcas v-model="marca"></Marcas>
					</div>

					<button type="button" class="btn btn-link mr-2" v-on:click="getModelos()">
						<span class="material-icons align-bottom">refresh</span>
					</button>
					<router-link class="btn btn-primary font-weight-bold" :to="{name:'admin.modelos.create'}">
						<span class="material-icons align-bottom">add_circle_outline</span>
						Nuevo
					</router-link>
				</div>
			</div>
		</div>

		<div class="card-body">
			<div class="text-center" v-if="loadingModelo">
				<div class="spinner-grow text-primary" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</div>
			<div v-else>
				<div class="table-responsive" v-if="modelosSearch.length>0">
					<table class="table table-sm">
						<thead>
							<tr>
								<th class="text-center" width="50">Código</th>
								<th class="text-center">Descripción</th>
								<th class="text-center" width="100">Estado</th>
								<th class="text-center"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(modelo, index) in modelosFilters" :key="index">
								<th>{{modelo.idModelo}}</th>
								<td>{{modelo.descripcion}}</td>
								<td class="text-center">
									<span class="badge badge-info" v-if="modelo.estado == 1">
										Activado
									</span>
									<span class="badge badge-danger" v-else>
										Desactivado
									</span>
								</td>
								<td class="text-right" width="158">
									<router-link class="btn btn-link btn-sm" :to="{name:'admin.modelos.edit', params: {id: modelo.idModelo}}" title="Editar">
										<span class="material-icons align-bottom">edit</span>
									</router-link>
									<deleteModelo :id="modelo.idModelo" @getModelos="getModelos()" />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div v-else>
					<div class="text-center py-5" v-if="modelos.length <= 0">
						<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
							<img v-lazy="imgSearch" class="img-fluid">
						</div>
						<h5>
						Aún no existen <strong>modelos</strong>.
						</h5>
					</div>
					<div class="text-center py-5" v-else>
						<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
							<img v-lazy="imgSearch" class="img-fluid">
						</div>
						<h5>
						No se encontraron resultados en la búsqueda.
						</h5>
					</div>
				</div>
				<div class="py-2 d-flex justify-content-center">
					<Paginacion :pagination="pagination" @paginate="changePagination" :offset="4" />
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import imgSearch from '@/assets/image/search_dark.webp'
	
	import { defineAsyncComponent } from 'vue'
	const Paginacion = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Pagination" */"@/components/Pagination.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const Marcas = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CatalogosMarcas" */"@/components/catalogos/Marcas.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	import deleteModelo from "@/views/admin/modelos/Delete.vue"

	export default {
		components: {
			deleteModelo,
			Paginacion,
			Marcas
		},
		data(){
			return {
				imgSearch,
				marca: '',
				pagination: {
					per_page: 10,
		            current_page: 1,
		            total: 0
		        },
			}
		},

		mounted(){
			this.pagination.total = (this.modelosSearch.length || 0)
			this.pagination.current_page = 1
		},

		methods: {
			getModelos(){
				this.$store.dispatch('getModelos', {
					order: 'idModelo desc'
				})
			},
			changePagination(page){
				this.pagination.current_page = page
			},
		},
		computed: {
			modelos(){
				return (this.$store.getters.modelos || [])
			},
			modelosSearch(){
				if(this.marca!=''){
					return this.modelos.filter(x => x.idMarca == this.marca)
				}

				return this.modelos
			},
			modelosFilters(){
				var ini = ((this.pagination.current_page*this.pagination.per_page)-this.pagination.per_page)
				var fin = ((this.pagination.current_page*this.pagination.per_page)-1)
				return this.modelosSearch.slice(ini, fin)
			},
			loadingModelo(){
				return this.$store.getters.loading
			},
			url(){
				return window.url
			}
		},
		watch:{
			modelosSearch: {
				handler(val){
					this.pagination.total = (val.length || 0)
					this.pagination.current_page = 1
				},
				deep: true
			}
		}
	}
</script>