<template>
	<div>
		<div class="card mb-3">
			<div class="card-header font-weight-bold">
				Líneas
			</div>
			<div class="card-body">
				<div class="d-flex flex-column flex-lg-row align-items-lg-end">
					<div class="flex-fill mb-3 mb-lg-0">
						<input type="text" name="descripcion" v-model="form.descripcion" class="form-control bg-light" placeholder="Ingrese línea">
					</div>

					<div class="mb-3 mb-lg-0 ml-2">
						<button type="button" class="btn btn-primary w-100" v-on:click="addLinea()" :disabled="!form.descripcion && !loadingAddLinea">
							Añadir
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="card">
			<div class="card-body">
				<div>
					<div class="text-center" v-if="loadingLinea">
						<div class="spinner-grow text-primary" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
					<div v-else>
						<div class="table-responsive" v-if="lineas.length>0">
							<table class="table table-sm">
								<thead>
									<tr>
										<th class="text-center" width="50">Código</th>
										<th class="text-center">Descripción</th>
										<th class="text-center" width="100">Estado</th>
										<th class="text-center"></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(linea, index) in lineasFilters" :key="index">
										<template v-if="editIndex==index">
											<th>{{index+1}}</th>
											<td>
												<input type="text" :name="'descripcion'+index" class="form-control bg-light" v-model="formEdit.descripcion" placeholder="Descripción">
											</td>
											<td class="text-center">
												<div class="custom-control custom-switch">
													<input type="checkbox" class="custom-control-input" :id="'estado'+index" v-model="formEdit.estado">
													<label class="custom-control-label" :for="'estado'+index"></label>
												</div>
											</td>
											<td class="text-right" width="100">
												<button type="button" class="btn btn-link btn-sm" title="Actualizar" v-on:click="updateHandler()" :disabled="loadingUpdate">
													<span class="material-icons align-bottom">save</span>
												</button>
												<button type="button" class="btn btn-link btn-sm" title="Cancelar" v-on:click="editHandler(-1)" :disabled="loadingUpdate">
													<span class="material-icons align-bottom">close</span>
												</button>
											</td>
										</template>
										<template v-else>
											<th>{{index+1}}</th>
											<td>{{linea.descripcion}}</td>
											<td class="text-center">
												<span class="badge badge-info" v-if="linea.estado == 1">
													Activado
												</span>
												<span class="badge badge-danger" v-else>
													Desactivado
												</span>
											</td>
											<td class="text-right" width="100">
												<button type="button" class="btn btn-link btn-sm" title="Editar" v-on:click="editHandler(index, linea)" :disabled="loadingUpdate">
													<span class="material-icons align-bottom">edit</span>
												</button>

												<deleteLinea :id="linea.idLinea" @getLineas="getLineas()" />
											</td>
										</template>
									</tr>
								</tbody>
							</table>
						</div>
						<div v-else>
							<div class="text-center py-5" v-if="lineas.length <= 0">
								<h5>Aún no existen <strong>líneas</strong>.</h5>
							</div>
						</div>
						<div class="py-2 d-flex justify-content-center">
							<Paginacion :pagination="pagination" @paginate="changePagination" :offset="4" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	import deleteLinea from "@/views/admin/lineas/Delete.vue"

	const Paginacion = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Pagination" */"@/components/Pagination.vue"),
		loadingComponent: {template:`<loading-component />`}
	})


	export default {
		props: ['idModelo'],
		components: {
			deleteLinea,
			Paginacion
		},
		data(){
			return {
				imgSearch: require('@/assets/image/search_dark.webp'),
				editIndex: -1,
				form:{
					descripcion: ''
				},
				formEdit:{
					descripcion: '',
					estado: false,
					idLinea: '',
					idModelo: ''
				},

				pagination: {
					per_page: 10,
		            current_page: 1,
		            total: 0
		        },

		        loadingAddLinea: false,
		        loadingLinea: false,
		        loadingUpdate: false
			}
		},

		mounted(){
			this.getLineas()
		},

		methods: {
			getLineas(){
				this.loadingLinea = true
				this.$store.dispatch('getLineas', {
					idModelo: this.idModeloDf,
					order: 'idLinea desc'
				}).then(x=> { return x }).catch(x => { return x }).then(()=>{
					this.loadingLinea = false
				})
			},

			changePagination(page){
				this.pagination.current_page = page
				//this.getPublicacionesDisp()
			},

			addLinea(){
				this.loadingAddLinea = true
				this.form.idModelo = this.idModeloDf
				this.$store.dispatch('addLinea', this.form).then(response => {
					//window.toastr.info('Línea agregada exitosamente.', 'Aviso')
					this.form.descripcion = ''
					this.getLineas()
					return response
				}).catch(error =>{
					if (error.response) {
						window.toastr.error(error.response.data.msg, 'Error')
					}else{
						window.toastr.error('Error al registrar línea', 'Error')
					}
					return error
				}).then(() => {
					this.loadingAddLinea = false
				})
			},

			editHandler(index, linea = null){
				this.editIndex = index
				if(linea!=null){
					this.formEdit.descripcion = linea.descripcion
					this.formEdit.estado = linea.estado == 1 ? true : false
					this.formEdit.idLinea = linea.idLinea
					this.formEdit.idModelo = linea.idModelo
				}else{
					this.formEdit.descripcion = ''
					this.formEdit.estado = false
					this.formEdit.idLinea = ''
					this.formEdit.idModelo = ''
				}
			},

			updateHandler(){
				this.loadingUpdate = true
				
				if(this.formEdit.estado){
					this.formEdit.estado = 1
				}else{
					this.formEdit.estado = 0
				}

				this.$store.dispatch('updateLinea', this.formEdit).then(response => {
					//window.toastr.info('Línea actualizada exitosamente.', 'Aviso')
					this.editHandler(-1)
					this.getLineas()
					return response
				}).catch(error =>{
					if (error.response) {
						window.toastr.error(error.response.data.msg, 'Error')
					}else{
						window.toastr.error('Error al registrar línea', 'Error')
					}
					return error
				}).then(() => {
					this.loadingUpdate = false
				})
			}
		},
		computed: {
			lineas(){
				return this.$store.getters.lineas || []
			},
			lineasFilters(){
				var ini = ((this.pagination.current_page*this.pagination.per_page)-this.pagination.per_page)
				var fin = ((this.pagination.current_page*this.pagination.per_page)-1)
				return this.lineas.slice(ini, fin)
			},
			url(){
				return window.url
			},
			idModeloDf(){
				return this.idModelo
			},
		},
		watch:{
			lineas: {
				handler(val){
					this.pagination.total = (val.length || 0)
				},
				deep: true
			}
		}
	}
</script>