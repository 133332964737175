<template>
	<div class="row justify-content-center h-100">
		<div class="col-lg-12">
			<div class="card overflow-hidden">
				<div class="card-header bg-secondary border-secondary">
					<h3 class="card-title text-center mb-0 font-weight-bold">Publicar vehículo nuevo</h3>
				</div>
				<div class="card-body">
					<form ref="userForm" v-on:submit.prevent="addVehiculo()" class="needs-validation was-validated" novalidate>
						<div class="row">
							<div class="col-lg-3">
								<label>Agregar tus fotos</label>
								<div class="form-row">
									<div class="col-lg-12 mb-3">
										<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto1" v-on:fileChange="uploadFile($event, '0')" style="height: 160px;">
											<img v-lazy="$filters.storageMin('https://clubcashin.com/storage/vehiculos/'+arrayFotos['0'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['0']">
											<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
										</Upload>
									</div>
									<div class="col-lg-12 mb-3">
										<div class="form-row">
											<div class="col-6 mb-2">
												<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto2" v-on:fileChange="uploadFile($event, '1')" style="height: 75px;">
													<img v-lazy="$filters.storageMin('https://clubcashin.com/storage/vehiculos/'+arrayFotos['1'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['1']">
													<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
												</Upload>
											</div>
											<div class="col-6 mb-2">
												<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto3" v-on:fileChange="uploadFile($event, '2')" style="height: 75px;">
													<img v-lazy="$filters.storageMin('https://clubcashin.com/storage/vehiculos/'+arrayFotos['2'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['2']">
													<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
												</Upload>
											</div>
											<div class="col-6 mb-2">
												<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto4" v-on:fileChange="uploadFile($event, '3')" style="height: 75px;">
													<img v-lazy="$filters.storageMin('https://clubcashin.com/storage/vehiculos/'+arrayFotos['3'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['3']">
													<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
												</Upload>
											</div>
											<div class="col-6 mb-2">
												<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto5" v-on:fileChange="uploadFile($event, '4')" style="height: 75px;">
													<img v-lazy="$filters.storageMin('https://clubcashin.com/storage/vehiculos/'+arrayFotos['4'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['4']">
													<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
												</Upload>
											</div>
										</div>
									</div>
									<div style="color:rgb(221,44,0);">
										{{msjErrorFoto}}
									</div>
								</div>
							</div>

							<div class="col-lg-9">
								<div class="row">
									<div class="col-lg-4 mb-2">
										<label for="marca">Marca</label>
										<select id="marca" class="custom-select" v-model="vehiculo.idMarca" required>
											<option value="" disabled selected>Marca</option>
											<option v-for="(marca, index) in marcas" :value="marca.idMarca" :key="index">{{marca.descripcion}}</option>
										</select>
									</div>

									<div class="col-lg-4 mb-2">
										<label for="modelo">Modelo</label>
										<select id="modelo" class="custom-select" v-model="vehiculo.idModelo" required :disabled="!vehiculo.idMarca">
											<option value="" disabled selected>Modelo</option>
											<option v-for="(modelo, index) in modelosFiltered" :value="modelo.idModelo" :key="index">{{modelo.descripcion}}</option>
										</select>
									</div>

									<div class="col-lg-4 mb-2">
										<label for="linea">Línea</label>
										<Lineas id="linea" :modelo="vehiculo.idModelo" v-model="vehiculo.idLinea" :disabled="!vehiculo.idModelo" />
									</div>

									<div class="col-lg-5 mb-2">
										<div class="d-flex align-items-center h-100">
											<div>
												<label>Transmisión</label>
												<div>
													<div class="custom-control custom-radio custom-control-inline">
														<input type="radio" id="transmisionManual" name="transmision" class="custom-control-input" v-model="vehiculo.transmision" value="1" required>
														<label class="custom-control-label" for="transmisionManual">Manual</label>
													</div>
													<div class="custom-control custom-radio custom-control-inline">
														<input type="radio" id="transmisionAutomatico" name="transmision" class="custom-control-input" v-model="vehiculo.transmision" value="2" required>
														<label class="custom-control-label" for="transmisionAutomatico">Automático</label>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col-lg-7 mb-3">
										<div class="row">
											<div class="col-lg-6 mb-2">
												<label for="anio" class="mb-0">Año</label>
												<input id="anio" v-model="vehiculo.ano" type="number" name="ano" class="form-control" minlength="4" maxlength="4" placeholder="Año" min="0" ref="focusInput" required>
											</div>

											<div class="col-lg-6 mb-2">
												<label for="pasajeros" class="mb-0">Pasajeros</label>
												<input id="pasajeros" v-model="vehiculo.numeroPasajeros" type="number" min="0" name="numeroPasajeros" class="form-control" placeholder="Pasajeros" required>
											</div>
										</div>
									</div>

									<div class="col-lg-4 mb-2">
										<label class="mb-0">Tipo</label>
										<select class="custom-select" v-model="vehiculo.idTipo" required>
											<option value="" disabled selected>Tipo</option>
											<option v-for="(tipo, index) in tipos" :value="tipo.idTipo" :key="index">{{tipo.descripcion}}</option>
										</select>
									</div>
									
									<div class="col-lg-3 mb-2">
										<label class="mb-0">Combustible</label>
										<select class="custom-select" v-model="vehiculo.tipoCombustible" required>
											<option value="" disabled selected>Combustible</option>
											<option value="1">Diesel</option>
											<option value="2">Electrico</option>
											<option value="3">Gasolina</option>
										</select>
									</div>

									<div class="col-lg-5 mb-2">
										<label class="mb-0">Motor</label>
										<input type="text" name="motor" class="form-control" maxlength="50" placeholder="Motor" v-model="vehiculo.motor" required/>
									</div>

									<div class="col-lg-7 mb-2" v-if="false">
										<label class="mb-0">Agencia</label>
										<input type="text" name="agencia" class="form-control" maxlength="50" placeholder="Agencia" v-model="vehiculo.origen" required/>
									</div>

									<div class="col-lg-3 mb-2">
										<label class="mb-0">Valor</label>
										<CurrencyInput type="text" name="precio" class="form-control" placeholder="Valor" v-model="vehiculo.precio" required />
									</div>

									<div class="col-lg-7 mb-3">
										<label class="mb-0">Video</label>
										<input type="text" name="video" class="form-control" maxlength="150" placeholder="Video" v-model="vehiculo.video"/>
									</div>

									<div class="col-lg-12 mb-3">
										<textarea v-model="vehiculo.descripcionVehiculo" name="desc" placeholder="Descripción" class="form-control" maxlength="1000" minlength="10" rows="5" required></textarea>
									</div>
								</div>
							</div>


							<div class="col-lg-12">
								<label>Seleccionar colores</label>
								<div class="form-row">
									<div class="col-lg-3 mb-2 text-center" v-for="(color, index) in colors" :value="color.idColor" :key="index">
										<div>
											<Upload :class="{'px-3 py-1 bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" :name="'fotoColor'+index" v-on:fileChange="vehiculosUploadFileColor($event, color.idColor)">
												<div class="w-100">
													<div :class="{'mb-2':true, 'deshabilitado':!color.check}">
														<img v-lazy="$filters.storageMin('https://clubcashin.com/storage/vehiculos-colores/'+color.file)" class="img-fluid" :alt="'Agregar foto del color '+color.descripcion" :title="'Agregar foto del color '+color.descripcion" style="height: 80px" v-if="color.file">
														<Car :style="{color: color.rgba, height: '80px'}" class="img-fluid border-svg" v-else />
													</div>

													<div class="custom-control custom-checkbox" v-on:click.stop="''">
														<input type="checkbox" class="custom-control-input" :id="'color'+index" v-model="color.check">
														<label class="custom-control-label" :for="'color'+index">
															<div class="font-weight-bold text-primary">
																{{color.descripcion}}
															</div>
														</label>
													</div>
												</div>
											</Upload>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-12">
								<div class="text-right px-2">
									<button type="submit" class="btn btn-inversion" :disabled="loadingPublicacion || !validateForm">
										<BtnLoading v-if="loadingPublicacion" />
										<span v-else>
											Publicar
										</span>
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	
	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const CurrencyInput = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Lineas = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CatalogosLineas" */"@/components/catalogos/Lineas.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const Upload = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Upload" */"@/components/Upload.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	import Car from '@/components/svg/Car.vue'

	export default {
		components: {
			BtnLoading,
			CurrencyInput,
			Upload,
			Car,
			Lineas
		},
		data(){
			return {
				validateForm: false,
				loadingPublicacion: false,
				vehiculo: {
					ano: '',
					idUsuario: '',
					kilometraje: 0,
					transmision: '',
					idTipo: '',
					idColor: '',
					idMarca: '',
					idModelo: '',
					tipoCombustible: '',
					numeroPasajeros: '',
					descripcionVehiculo: '',
					arrayFotos: [],
					precio: '',
					origen: '',
					motor: '',
					colores: [],
					isNew: 1,
					video: ''
				},
				colors: [],
				tipos: [],
				marcas: [],
				modelos: [],

				msjErrorFoto: '',
			}
		},
		mounted(){
			this.getCatalogos()
		},
		methods:{
			getCatalogos(){
				this.$store.dispatch('vehiculosGetTipos').then(res=>{
					this.tipos = res.data || []
				}).catch()
				this.$store.dispatch('vehiculosGetColores').then(res=>{
					this.colors = (res.data || []).map(x => {
						return {
							idColor: x.idColor,
							descripcion: x.descripcion,
							rgba: x.rgba,
							estado: x.estado,
							fechaCreacion: x.fechaCreacion,
							check: 0,
							file: ''
						}
					})
				}).catch()
				this.$store.dispatch('vehiculosGetMarcas').then(res=>{
					this.marcas = res.data || []
				}).catch()
				this.$store.dispatch('vehiculosGetModelos').then(res=>{
					this.modelos = res.data || []
				}).catch()
			},
			addVehiculo(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				if (this.arrayFotos.length<=0) {
					this.msjErrorFoto = 'Debes agregar al menos una foto.'
					window.toastr.error(this.msjErrorFoto, 'Error')
					return
				}
				this.loadingPublicacion = true
				this.vehiculo.idUsuario = this.user.idusuario
				this.vehiculo.colores = this.coloresForm

				this.$store.dispatch('addVehiculos', this.vehiculo).then(response => {
					window.toastr.info('Vehículo nuevo creado éxitosamente.', 'Aviso')
					this.$router.push({name: 'admin.vehiculos-nuevos'})
					return response
				}).catch(error =>{
					if (error.response) {
						window.toastr.error(error.response.data.msg, 'Error')
					}else{
						window.toastr.error('Error al registrar usuario', 'Error')
					}
					return error
				}).then(() => {
					this.loadingPublicacion = false
				})
			},
			uploadFile(file, tipo){
				this.loadingFile = true
				var formData = new FormData()
				formData.append('images', file)
		        formData.append('modulo', 'vehiculos')

				this.$store.dispatch('uploadFile', formData).then(response => {
					this.vehiculo.arrayFotos[tipo] = response.data[0]
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingFile = false
				})
			},

			vehiculosUploadFileColor(file, tipo){
				this.loadingFile = true
				var formData = new FormData()
				formData.append('images', file)
		        formData.append('modulo', 'vehiculos-colores')
		        
				this.$store.dispatch('uploadFile', formData).then(response => {
					var indexFilter = this.colors.findIndex(x => x.idColor == tipo)
					if(indexFilter>-1){
						this.colors[indexFilter].file = response.data[0]
						this.colors[indexFilter].check = true
					}
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingFile = false
				})
			},
			checkValidity(){
				if (!this.$refs.userForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.userForm.checkValidity()
				}
			},
		},
		computed:{
			coloresForm(){
				return  this.colors.filter(x => x.check == 1).map(x => {
					return {
						'idColor': x.idColor, 
						'file': x.file, 
						'check': x.check
					}
				})
			},
			user(){
				return this.$store.getters.currentUser || []
			},
			arrayFotos(){
				return this.vehiculo.arrayFotos || []
			},

			modelosFiltered(){
				if (!this.vehiculo.idMarca) {
					return []
				}
				return this.modelos.filter(x => x.idMarca == this.vehiculo.idMarca)
			},
			isMobile() {
				return this.$store.getters.isMobile
			},
			isLoggedIn() {
				return this.$store.getters.isLoggedIn
			},
		},
		watch:{
			'vehiculo':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		},
	}
</script>