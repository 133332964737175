<template>
	<div>
		<ul class="nav nav-tabs flex-column flex-md-row nav-stacked">
			<li class="nav-item">
				<router-link :to="{name:'admin.apartados.pendientes'}" class="nav-link" exact-active-class="active">
					Pendientes
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{name:'admin.apartados.aprobadas'}" class="nav-link" exact-active-class="active">
					Aprobadas
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{name:'admin.apartados.rechazadas'}" class="nav-link" exact-active-class="active">
					Rechazadas
				</router-link>
			</li>
		</ul>
		<router-view />
	</div>
</template>
<script type="text/javascript">
	export default {
		mounted(){
			this.getApartarVehiculos()
		},
		methods: {
			getApartarVehiculos(){
				this.$store.dispatch('getApartarVehiculos')
			},
		}
	}
</script>