<template>
	<form ref="marcaForm" v-on:submit.prevent="addMarcaForm()" class="needs-validation was-validated">
		<div class="card">
			<div class="card-header font-weight-bold">
				Agregar marca
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-lg-12 mb-3">
						<label for="descripcion" class="mb-0">Descripción:</label>
						<input type="text" id="descripcion" name="descripcion" v-model="form.descripcion" class="form-control bg-light" placeholder="Descripción" maxlength="50" required>
					</div>

					<div class="col-lg-12 mb-3">
						<label for="estado">Estado:</label>
						<div>
							<div class="custom-control custom-switch">
								<input type="checkbox" class="custom-control-input" id="estado" v-model="form.estado">
								<label class="custom-control-label" for="estado">{{form.estado == 1 ? 'Activo' : 'Inactivo'}}</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer text-right">
				<button type="submit" class="btn btn-primary mr-3" :disabled="loadingMarcas">
					<span class="material-icons align-bottom">save</span>
					Guardar
				</button>
				<router-link class="btn btn-link" :to="{name:'admin.marcas'}" :disabled="!validateForm || loadingMarcas">
					<span class="material-icons align-bottom">arrow_back</span>
					Volver
				</router-link>
			</div>
		</div>
	</form>
</template>
<script type="text/javascript">
	export default {
		data(){
			return {
				form: {
					descripcion: '',
					estado: 0,
				},
				loadingMarcas: false,
				validateForm: false
			}
		},
		mounted(){
			this.setData(this.marca)
		},
		methods: {
			addMarcaForm(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingMarcas = true
				this.form.estado = (this.form.estado) ? 1 : 0
				this.form.idMarca = this.id
				this.$store.dispatch('updateMarca', this.form).then(response => {
					window.toastr.info('Marca actualizada exitosamente.', 'Aviso')
					this.$store.dispatch('getMarcas', {
						order: 'idMarca desc'
					})
					this.$router.push({name: 'admin.marcas'})
					return response
				}).catch(error =>{
					if (error.response) {
						window.toastr.error(error.response.data.msg, 'Error')
					}else{
						window.toastr.error('Error al actualizar marca', 'Error')
					}
					return error
				}).then(() => {
					this.loadingMarcas = false
				})
			},
			checkValidity(){
				if (!this.$refs.marcaForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.marcaForm.checkValidity()
				}
			},
			setData(val){
				this.form.descripcion = val.descripcion || ''
				this.form.estado = (val.estado == 1) ? true : false
			}
		},
		computed: {
			id(){
				return this.$route.params.id || -1
			},
			marcas(){
				return this.$store.getters.marcas || []
			},
			marca(){
				var data = this.marcas.filter(x => x.idMarca == this.id) || []
				if (data.length>0) {
					return data[0]
				}
				return []
			},
		},
		watch: {
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		}
	}
</script>