<template>
	<div>
		<div class="bg-white p-3 rounded">
			<div class="input-group flex-nowrap mb-3" v-if="apartadosCurrent.length > 0">
				<div class="input-group-prepend">
					<span class="input-group-text" id="addon-wrapping">
						<img v-lazy="imgSearch" width="18" height="18">
					</span>
				</div>
				<input id="searchApartados" type="text" class="form-control bg-light border-left-0 form-control-placeholder-bold" placeholder="Buscar" aria-label="Buscar" aria-describedby="addon-wrapping" v-model="searchApartados" name="searchApartados">
			</div>

			<div class="table-responsive" v-if="apartadosCurrentFiltered.length>0">
				<table class="table table-sm">
					<thead>
						<tr>
							<th class="text-center" style="width: 35px;">No.</th>
							<th v-on:click="changeSorting('modelo')" class="cursor-pointer text-center d-none d-lg-table-cell">
								Vehículo
								<span v-if="sort.column == 'modelo'">
									<span class="material-icons" style="font-size: 12px;">{{sort.icon}}</span>
								</span>
							</th>
							<th v-on:click="changeSorting('nombre')" class="cursor-pointer text-center">
								Usuario
								<span v-if="sort.column == 'nombre'">
									<span class="material-icons" style="font-size: 12px;">{{sort.icon}}</span>
								</span>
							</th>
							<th class="text-center" style="width: 50px">Acciones</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(vehiculo, index) in apartadosCurrentFiltered" :key="index">
							<th>{{index+1}}</th>
							<td>
								<img style="width:35px;height:35px;" v-lazy="$filters.storage(vehiculo.ruta, 50)" class="rounded-circle mr-2" />
								<router-link :to="{name:'vehiculos.show', params: {id: vehiculo.idVehiculo}}">
									{{vehiculo.marca}} {{vehiculo.modelo}} {{vehiculo.ano}}
								</router-link>
							</td>
							<td>
								<img style="width:35px;height:35px;" v-lazy="$filters.storage(vehiculo.foto, 50)" class="rounded-circle mr-2" />
								{{vehiculo.nombre}} {{vehiculo.apellido}}
							</td>
							<td class="text-center">
								<router-link :to="{name:'admin.apartados.show', params:{id:vehiculo.idApartado}}" class="btn btn-primary btn-sm p-1" title="Ver solicitud" style="border-radius: 5px;">
									<img v-lazy="imgSearchWhite" width="18" height="18">
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-else>
				<div class="text-center py-5" v-if="apartadosCurrent.length <= 0">
					<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
						<img v-lazy="imgSearch" class="img-fluid">
					</div>
					<h5>
					Aún no existen vehículos apartados.
					</h5>
				</div>
				<div class="text-center py-5" v-else>
					<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
						<img v-lazy="imgSearch" class="img-fluid">
					</div>
					<h5>
					No se encontraron resultados en la búsqueda.
					</h5>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import imgSearch from '@/assets/image/search_dark.webp'
	import imgSearchWhite from '@/assets/image/search.webp'
	export default {
		data(){
			return {
				imgSearch,
				imgSearchWhite,
				searchApartados: '',
				sort: {
					column: '',
					descending: false,
					icon: ''
				}
			}
		},
		methods: {
			changeSorting(column){
				return column
				/*var sort = this.sort;
			    if (sort.column == column) {
			        sort.descending = !sort.descending;
			        if (sort.descending) {
			          sort.icon = 'arrow_upward'
			        }else{
			          sort.icon = 'arrow_downward'
			        }
			    } else {
			        sort.column = column;
			        sort.descending = false;
			        sort.icon = 'arrow_downward'
			    }*/
			}
		},
		computed: {
			apartados(){
				return this.$store.getters.apartados || []
			},
			apartadosCurrent(){
				if (this.apartados.constructor === Array) {
					return this.apartados.filter(x => x.estadoApartado == 2 || x.estadoApartado == 3)
				}
				return []
			},
			apartadosCurrentFiltered(){
				if (this.searchApartados != '') {
					if (this.apartadosCurrent.constructor === Array) {
						return this.apartadosCurrent.filter(x => 
							(x.nombre || '').toString().includes(this.searchApartado) ||
							(x.apellido || '').toString().includes(this.searchApartado) ||
							(x.ruta || '').toString().includes(this.searchApartado) ||
							(x.marca || '').toString().includes(this.searchApartado) ||
							(x.modelo || '').toString().includes(this.searchApartado) ||
							(x.ano || '').toString().includes(this.searchApartado) ||
							(x.idApartado || '').toString().includes(this.searchApartado)
						)
					}
				}
				return this.apartadosCurrent || []
			}
		}
	}
</script>