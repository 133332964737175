<template>
    <div class="card" ref="cal"></div>
</template>
<script>
    import {Calendar} from '@fullcalendar/core'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import timeGridPlugin from '@fullcalendar/timegrid'
    import listPlugin from '@fullcalendar/list'
    import esLocale from '@fullcalendar/core/locales/es'
    import interactionPlugin from '@fullcalendar/interaction'
    //import bootstrapPlugin from '@fullcalendar/bootstrap'

    export default {
        name:'myCalendar',
        data(){
            return {
                eventos: []
            }
        },
        mounted(){
            this.getAgenda()
        },
        methods:{
            init(){
                const Tcalendar = new Calendar(this.$el, {
                    plugins: [
                        dayGridPlugin,
                        timeGridPlugin,
                        listPlugin,
                        interactionPlugin,
                        //bootstrapPlugin
                    ],
                    locale:esLocale,
                    initialView: 'dayGridMonth',
                    buttonText: {prev:'<', next:'>', prevYear: '<<', nextYear: '>>'},
                    headerToolbar: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'prevYear,nextYear,dayGridMonth,timeGridWeek,timeGridDay,listMonth'
                    },
                    editable: false,
                    selectable: true,
                    selectMirror: true,
                    dayMaxEvents: true,
                    weekends: true,
                    eventClick: this.handleEventClick,
                    events: this.eventos
                })
                Tcalendar.currentData.theme.classes.button = 'btn btn-primary'
                Tcalendar.currentData.theme.classes.buttonGroup = 'btn-group'
                Tcalendar.render()
            },
            handleEventClick(info) {
                this.$router.push({name: 'vehiculos.show', params: {id: info.event.id}})
            },
            getAgenda(){
                this.$store.dispatch('getAgenda').then(response => {
                    this.eventos = response.data || []
                    return response
                }).catch(error =>{
                    return error
                })
            }
        },
        watch: {
            'eventos': {
                handler(val){
                    this.init()
                    return val
                },
                deep: true
            }
        }
    }
</script>