<template>
	<div class="card">
		<div class="card-header">
			<div class="d-flex align-items-center justify-content-between">
				<h3 class="card-title mb-0">
					Predios
				</h3>
				<div>
					<button type="button" class="btn btn-link mr-2" v-on:click="getPredios()">
						<span class="material-icons align-bottom">refresh</span>
					</button>
					<router-link class="btn btn-primary font-weight-bold" :to="{name:'admin.predios.create'}">
						<span class="material-icons align-bottom">add_circle_outline</span>
						Agregar predio
					</router-link>
				</div>
			</div>
		</div>
		<div class="card-body text-center" v-if="loadingPredio">
			<div class="spinner-grow text-primary" role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</div>
		<div class="card-body" v-else>
			<div class="table-responsive" v-if="predios.length>0">
				<table class="table">
					<thead>
						<tr>
							<th class="text-center" width="50">Código</th>
							<th class="text-center">Descripción</th>
							<th class="text-center" width="100">Estado</th>
							<th class="text-center">Correo</th>
							<th class="text-center"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(predio, index) in predios" :key="index">
							<th>{{predio.codigo_predio}}</th>
							<td>{{predio.nombre}}</td>
							<td class="text-center">
								<span class="badge badge-info" v-if="predio.estado == 0">
									Activado
								</span>
								<span class="badge badge-danger" v-else>
									Desactivado
								</span>
							</td>
							<td>
								<a :href="'mailto:'+predio.correo" v-if="predio.correo">
									{{predio.correo}}
								</a>
							</td>
							<td class="text-right" width="158">
								<router-link class="btn btn-link btn-sm" :to="{name:'admin.predios.show', params: {id: predio.slug}}" title="Enlace del predio" target="_blank">
									<span class="material-icons align-bottom">search</span>
								</router-link>
								<router-link class="btn btn-link btn-sm" :to="{name:'admin.predios.edit', params: {id: predio.slug}}" title="Editar">
									<span class="material-icons align-bottom">edit</span>
								</router-link>
								<deletePredio :id="predio.codigo_predio" @getPredios="getPredios" />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-else>
				<div class="text-center py-5" v-if="predios.length <= 0">
					<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
						<img v-lazy="imgSearch" class="img-fluid">
					</div>
					<h5>
					Aún no existen <strong>predios</strong>.
					</h5>
				</div>
				<div class="text-center py-5" v-else>
					<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
						<img v-lazy="imgSearch" class="img-fluid">
					</div>
					<h5>
					No se encontraron resultados en la búsqueda.
					</h5>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import deletePredio from "@/views/admin/predios/Delete.vue"
	
	export default {
		components: {
			deletePredio
		},
		data(){
			return {
				imgSearch: require('@/assets/image/search_dark.webp')
			}
		},
		methods: {
			getPredios(){
				this.$store.dispatch('getPredios')
			}
		},
		computed: {
			predios(){
				return this.$store.getters.predios || []
			},
			loadingPredio(){
				return this.$store.getters.loading
			},
			url(){
				return window.url
			}
		}
	}
</script>