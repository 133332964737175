<template>
	<div class="container-fluid">
		<div class="row justify-content-center align-items-center py-3 bg-credito" style="min-height: calc(100vh - 164px);">
			<div class="col-md-5">
				<div class="card">
					<div class="card-header text-right bg-primary text-white">
						<router-link :to="{name:'perfil.favoritos.usuarios'}">
							<img src="@/assets/image/close_white.webp" width="15" height="15">
						</router-link>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-md-6 mb-3">
								<div class="mb-3">
									<button type="button" class="btn btn-link" v-on:click="deleteFavorite()" v-if="usuario.favorito" title="Quitar como favorito">
										<i class="material-icons text-danger">favorite</i>
									</button>
									<button type="button" class="btn btn-link" v-on:click="addFavorite()" v-if="!usuario.favorito" title="Marcar como favorito">
										<i class="material-icons">favorite_border</i>
									</button>
								</div>
								<div class="bg-primary rounded px-3 pt-3 mb-3 d-flex justify-content-center align-items-end mx-3">
									<img src="@/assets/image/usuarios.webp" class="img-fluid" alt="Foto perfil" style="height: 130px;">
								</div>
								<h1 class="text-center font-weight-bold">{{usuario.alias}}</h1>
							</div>
							<div class="col-md-6 mb-3">
								<div class="form-row justify-content-center">
									<div class="col-md-12 mb-2" v-if="prendas.length>0">
										<div class="card rounded">
											<img v-lazy="$filters.storage(prenda.ruta, 250)" class="card-img-top" :alt="prenda.nombre" style="max-height: 200px;">
											<div :class="{'px-2 font-weight-bold text-white':true, 'bg-inversion':prenda.estado==1, 'bg-gray':prenda.estado!=1}">
												<div v-if="prenda.estado==1">
													Invierte
												</div>
												<div v-if="prenda.estado==5">
													Préstamo otorgado
												</div>
											</div>
											<div class="rounded-bottom bg-primary text-white py-2 px-3">
												<div class="text-truncate ">
													<span class="font-weight-bold">{{prenda.nombre}}</span>
												</div>
												> monto: {{$filters.currency(prenda.precio)}}
											</div>
										</div>
										<div class="col-md-12 mt-1">
											<div class="d-flex justify-content-between align-items-center">
												<div>
													{{currentPrenda+1}}/{{prendas.length}}
												</div>
												<ul class="justify-content-end pagination mb-0">
													<li class="pagination-prev page-item">
														<button type="button" v-on:click="changenPrev()" :class="{'page-link mr-2':true, 'bg-light':currentPrenda<=0, 'bg-primary':currentPrenda>0}" style="border-radius: 5px;">
															<img src="@/assets/image/previous_item.webp" style="height: 17px;">
														</button>
													</li>
													<li class="pagination-prev page-item">
														<button type="button" v-on:click="changenNext()" :class="{'page-link':true, 'bg-primary':prendas.length!=(currentPrenda+1), 'bg-light':prendas.length==(currentPrenda+1)}" style="border-radius: 5px;">
															<img src="@/assets/image/next_item.webp" style="height: 17px;">
														</button>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div class="col-md-12" v-else>
										<div class="bg-light rounded p-5 text-center">
											<img src="@/assets/image/info.webp" width="50" height="50">
											<br>
											<br>
											<p><small class="font-weight-bold">Aún no hay activos</small></p>
											<br>
											<div class="border-primary mx-3" style="border-style: dashed;"></div>
											<br>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	export default {
		data(){
			return {
				usuario: {},
				prendas: [],
				currentPrenda: 0,
				loadingUser: false,
				loadingPublicaciones: false
			}
		},
		mounted(){
			this.getData()
		},
		methods: {
			getData(){
				if (!this.user.idusuario) {return}
				this.getUserSelected()
				this.getPublicacionesByUser()
			},
			getUserSelected(){
				this.loadingUser = true
				this.$store.dispatch('getUserSelected',{
					idusuario: this.id,
					idusuarioCreador: this.user.idusuario
				}).then(response => {
					var data = response.data[0] || []
					this.usuario = data || {}
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingUser = false
				})
			},
			getPublicacionesByUser(){
				this.loadingPublicaciones = true
				this.$store.dispatch('getPublicacionesByUser',{
					idusuario: this.id
				}).then(response => {
					this.prendas = response.data || []
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPublicaciones = false
				})
			},
			deleteFavorite(){
				this.$store.dispatch('deleteFavorite', this.forms).then(response => {
					this.usuario.favorito = 0
					window.toastr.info('Favorito eliminado.', 'Aviso')
					return response
				}).catch(error =>{
					return error
				})
			},
			addFavorite(){
				this.$store.dispatch('addFavorite', this.forms).then(response => {
					this.usuario.favorito = 1
					window.toastr.info('Favorito agregado.', 'Aviso')
					return response
				}).catch(error =>{
					return error
				})
			},
			changenPrev(){
				this.currentPrenda--
				if (this.currentPrenda<0) {
					this.currentPrenda = (this.prendas.length-1)
					return
				}
			},
			changenNext(){
				this.currentPrenda++
				if (this.currentPrenda>(this.prendas.length-1)) {
					this.currentPrenda = 0
					return
				}
			}
		},
		computed: {
			user(){
				return this.$store.getters.currentUser || {}
			},
			id(){
				return this.$route.params.id || -1
			},
			prenda(){
				return this.prendas[this.currentPrenda] || {}
			},
			forms(){
				return {
					idusuarioCreador: this.user.idusuario,
					idusuarioFav: this.id,
					fechaCreacion: Math.round(new Date().getTime()/1000.0)
				}
			}
		},
		watch:{
			'user':{
				handler(val){
					this.getData()
					return val
				},
				deep: true
			}
		}
	}
</script>