<template>
	<div class="row">
		<div class="col-lg-4 mb-3">
			<form ref="modeloForm" v-on:submit.prevent="addmodeloForm()" class="needs-validation was-validated">
				<div class="card">
					<div class="card-header font-weight-bold">
						Editar modelo
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-lg-12 mb-3">
								<label for="descripcion" class="mb-0">Descripción:</label>
								<input type="text" id="descripcion" name="descripcion" v-model="form.descripcion" class="form-control bg-light" placeholder="Descripción" maxlength="50" required>
							</div>

							<div class="col-lg-12 mb-3">
								<label for="marca">Marca:</label>
								<Marcas v-model="form.idMarca" />
							</div>

							<div class="col-lg-12 mb-3">
								<label for="estado">Estado:</label>
								<div>
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="estado" v-model="form.estado">
										<label class="custom-control-label" for="estado">{{form.estado == 1 ? 'Activo' : 'Inactivo'}}</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer text-right">
						<button type="submit" class="btn btn-primary mr-3" :disabled="loadingModelos">
							<span class="material-icons align-bottom">save</span>
							Guardar
						</button>
						<router-link class="btn btn-link" :to="{name:'admin.modelos'}" :disabled="!validateForm || loadingModelos">
							<span class="material-icons align-bottom">arrow_back</span>
							Volver
						</router-link>
					</div>
				</div>
			</form>
		</div>

		<div class="col-lg-8 mb-3">
			<Lineas :idModelo="id" />
		</div>

	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	import Lineas from "@/views/admin/lineas/Index.vue"
	
	const Marcas = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CatalogosMarcas" */"@/components/catalogos/Marcas.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		data(){
			return {
				form: {
					descripcion: '',
					idMarca: '',
					estado: 0,
				},
				loadingModelos: false,
				validateForm: false
			}
		},
		components: {
			Marcas,
			Lineas
		},
		mounted(){
			this.setData(this.modelo)
		},
		methods: {
			addmodeloForm(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingModelos = true
				this.form.estado = (this.form.estado) ? 1 : 0
				this.form.idModelo = this.id
				this.$store.dispatch('updateModelo', this.form).then(response => {
					window.toastr.info('Línea actualizada exitosamente.', 'Aviso')
					this.$store.dispatch('getModelos', {
						order: 'idModelo desc'
					})
					this.$router.push({name: 'admin.modelos'})
					return response
				}).catch(error =>{
					if (error.response) {
						window.toastr.error(error.response.data.msg, 'Error')
					}else{
						window.toastr.error('Error al actualizar línea', 'Error')
					}
					return error
				}).then(() => {
					this.loadingModelos = false
				})
			},
			checkValidity(){
				if (!this.$refs.modeloForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.modeloForm.checkValidity()
				}
			},
			setData(val){
				this.form.descripcion = val.descripcion || ''
				this.form.idMarca = val.idMarca || ''
				this.form.estado = (val.estado == 1) ? true : false
			}
		},
		computed: {
			id(){
				return this.$route.params.id || -1
			},
			modelos(){
				return this.$store.getters.modelos || []
			},
			modelo(){
				var data = this.modelos.filter(x => x.idModelo == this.id) || []
				if (data.length>0) {
					return data[0]
				}
				return []
			},
		},
		watch: {
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			},
			'modelo':{
				handler(val){
					this.setData(val)
				},
				deep: true
			}
		}
	}
</script>