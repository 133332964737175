<template>
	<div class="bg-white">
		<div style="height: 200px;" class="position-relative">
			<img v-lazy="$filters.url('https://clubcashin.com/storage/predios/'+predio.portada)" class="object-fit-cover w-100 h-100 shadow">
			
			<div class="text-center" style="z-index: 100; position: absolute; left: 0px; right: 0px; bottom: -20px;">
				<img v-lazy="$filters.url('https://clubcashin.com/storage/predios/'+predio.logo)" class="img-thumbnail object-fit-contain rounded-circle bg-white" style="height: 200px; width: 200px;">
			</div>
		</div>

		<div class="container">
			<h1 class="text-center mt-4 mb-3 border-bottom" v-text="predio.nombre"></h1>
			<div class="d-flex align-items-center justify-content-center pb-3">
				<div class="mr-3" v-if="predio.telefono">
					<a :href="'tel:'+predio.telefono">
						<font-awesome-icon :icon="['fas', 'phone']" style="font-size: 30px;" class="social-correo" />
					</a>
				</div>
				<div class="mr-3" v-if="predio.mail">
					<a :href="'mailto:'+predio.mail">
						<font-awesome-icon :icon="['fas', 'envelope']" style="font-size: 35px;" class="social-telefono" />
					</a>
				</div>
				<div v-for="(red, index) in tiposRedes" :key="index" class="mr-3">
					<a :href="red.link" target="_blank">
						<font-awesome-icon :icon="['fab', 'facebook']" style="font-size: 35px;" v-if="red.tipo == 'Facebook'" class="social-facebook" />
						<font-awesome-icon :icon="['fab', 'twitter']" style="font-size: 35px;" v-else-if="red.tipo == 'Twitter'" class="social-twitter" />
						<font-awesome-icon :icon="['fab', 'youtube']" style="font-size: 35px;" v-else-if="red.tipo == 'YouTube'" class="social-youtube" />
						<font-awesome-icon :icon="['fab', 'instagram']" style="font-size: 35px;" v-else-if="red.tipo == 'Instagram'" class="social-instagram" />
						<font-awesome-icon :icon="['fas', 'link']" style="font-size: 35px;" v-else-if="red.tipo == 'Sitio Web'" class="social-web" />
					</a>
				</div>
			</div>
		</div>

		<div class="bg-secondary py-5">
			<div class="container">

				<div class="card mb-5" v-if="vehiculos.length>0">
					<div class="card-body">
						<div class="row justify-content-center" v-if="vehiculosFilteredPagination.length>0">
							<template v-for="(vehiculo, index) in vehiculosFilteredPagination" :key="index">
								<div class="col-lg-3 mb-3">
									<router-link class="card rounded-sm cursor-pointer text-decoration-none" :to="{name:'vehiculos.show', params:{id:vehiculo.idVehiculo}}">
										<div :class="{'card-img-top position-relative':true, 'publicacion-deshabilitada':vehiculo.estatus!=1}">
											<img v-lazy="$filters.storage(vehiculo.ruta, 200)" class="w-100 h-180 rounded-top" :alt="vehiculo.marca" style="object-fit: cover; height: 160px;" v-if="vehiculo.ruta">
											<div v-if="vehiculo.idOferta!=null" class="badge badge-light position-absolute" style="bottom:10px; left: 10px;" title="Haz realizado una oferta por este vehículo">
												Oferta realizada
											</div>
											<div v-if="vehiculo.estatus==1" class="badge badge-inversion position-absolute" style="top:10px; right: 10px;">
												Disponible
											</div>
											<div v-if="vehiculo.estatus==2" class="badge badge-gray position-absolute" style="top:10px; right: 10px;">
												Apartado
											</div>
											<div v-if="vehiculo.estatus==3" class="badge badge-danger position-absolute" style="top:10px; right: 10px;">
												Vendido
											</div>

											<div class="badge badge-light position-absolute" style="bottom: 10px; right: 10px;">
												{{$filters.currency(vehiculo.precio)}}
											</div>
										</div>
										<div class="rounded-bottom bg-primary text-white py-2 px-3">
											<div class="text-truncate ">
												<span class="font-weight-bold">{{vehiculo.marca}} {{vehiculo.modelo}}</span>
											</div>
											<div class="d-flex align-items-center justify-content-between">
												<div class="font-small align-items-center d-flex">
													<span class="material-icons align-self-center mr-1">event</span> {{vehiculo.ano}}
												</div>
												<div class="font-small align-items-center d-flex">
													<span class="material-icons mr-1">airline_seat_recline_normal</span> {{vehiculo.numeroPasajeros}}
												</div>
												<div class="font-small align-items-center d-flex justify-content-end">
													{{vehiculo.desTransmision}}
												</div>
											</div>
										</div>
									</router-link>
								</div>

								<div class="w-100 d-none d-md-block" v-if="index % 3 == 2"></div>

							</template>
						</div>

						<div class="text-center">
							<Paginacion :pagination="pagination" @paginate="changePagination" :offset="4" class="d-flex justify-content-center" />
						</div>

					</div>
				</div>

				<div class="card">
					<div class="card-body">
						<div class="row justify-content-center">
							<div class="col-lg-10 mb-4">
								<Cotizador v-model="monto" tipo="auto-compras" @enganche="engancheHandler" :logo="$filters.url('https://clubcashin.com/storage/predios/'+predio.logo)" />
							</div>
						</div>

						<div class="card overflow-hidden mb-4" id="formulario">
							<div class="card-body bg-light">
								<div class="row justify-content-center align-items-stretch">
									<div class="col-lg-3 text-center my-auto">
										<div>
											<img src="@/assets/image/carro-compra-felicidad.webp" class="rounded-circle" alt="Vehículo compra feliz" width="250" height="250" style="object-fit: cover; border: 20px solid #fff;" />
										</div>
									</div>
									<div class="col-lg-8">
										<div class="mb-3 w-50 mx-auto">
											<img src="@/assets/image/logo_dark.webp" class="img-fluid my-3" alt="Club Cashin">
										</div>
										<div class="form-row">
											<PrecalifiqueForm tipo="predio" :predio="id" :predionombre="predio.nombre" v-model="monto" :enganche="enganche"></PrecalifiqueForm>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="d-flex align-items-center justify-content-center pt-5">
				<div>
					<div class="text-center text-white">
						Powered by
					</div>
					<a href="https://clubcashin.com/" target="_blank">
						<img src="@/assets/image/logo_dark.webp" height="30" alt="Logo" loading="lazy">
					</a>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	
	const FontAwesomeIcon = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "fortawesome" */"@fortawesome/vue-fontawesome").then(mod => mod.FontAwesomeIcon),
		loadingComponent: {template:`<loading-component />`}
	})

	const Paginacion = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Pagination" */"@/components/Pagination.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const Cotizador = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Cotizador" */"@/components/Cotizador.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const PrecalifiqueForm = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "PrecalifiqueForm" */"@/components/precalifique/Form.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			FontAwesomeIcon,
			Cotizador,
			Paginacion,
			
			PrecalifiqueForm
		},
		data(){
			return {
				tiposRedes: [],
				predio: {},
				vehiculos: [],
				pagination: {
					per_page: 18,
		            current_page: 1,
		            total: 50
		        },
				
				monto: 1,
				enganche: 0,

				loadingVehiculos: false,
				validateForm: false,
			}
		},
		mounted(){
			this.showPredio()

			if(this.sc && this.sc!=''){
				document.getElementById(this.sc).scrollIntoView({behavior: "smooth"})
			}
		},
		methods: {
			engancheHandler(val){
				this.enganche = val
			},
			getPredioRedes(val){
				this.$store.dispatch('getPredioRedes', {
					codigo_predio: val
				}).then(res => {
					this.tiposRedes = res.data || []
				}).catch()
			},

			showPredio(){
				this.$store.dispatch('showPredio', {
					slug: this.id
				}).then(res => {
					this.predio = res.data || {}
					this.getVehiculosDisponibles()
				}).catch()
			},

			changePagination(page){
				this.pagination.current_page = page
				//this.getPublicacionesDisp()
			},

			getVehiculosDisponibles(){
				//if (!this.user.idusuario) {return}
				this.loadingVehiculos = true
				this.$store.dispatch('getVehiculosDisponibles', {
					predio: this.predio.codigo_predio
				}).then(response => {
					this.vehiculos = (response.data || [])
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingVehiculos = false
				})
			},
		},
		computed: {
			id(){
				return this.$route.params.id || -1
			},
			url(){
				return window.url
			},
			query(){
				return this.$route.query || {}
			},
			sc(){
				return this.query.sc || ''
			},

			ini(){
				return ((this.pagination.current_page*this.pagination.per_page)-this.pagination.per_page)
			},
			fin(){
				return ((this.pagination.current_page*this.pagination.per_page))
			},
			vehiculosFilteredPagination(){
				return this.vehiculos.slice(this.ini, this.fin)
			},
		},
		watch: {
			'predio':{
				handler(val){
					this.getPredioRedes(val.codigo_predio)
				},
				deep: true
			},
			sc(val){
				if (val) {
					document.getElementById(val).scrollIntoView({behavior: "smooth"})
				}
			}
		}
	}
</script>