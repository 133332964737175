<template>
	<div>	
		<ul class="nav nav-tabs flex-column flex-md-row justify-content-between nav-stacked">
			<li class="nav-item mr-auto">
				<router-link :to="{name:'admin.vehiculos-nuevos.create'}" class="btn btn-primary btn-sm">
					Agregar
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{name:'admin.vehiculos-nuevos'}" class="nav-link" exact-active-class="active">
					Aprobados
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{name:'admin.vehiculos-nuevos.rechazadas'}" class="nav-link" exact-active-class="active">
					Rechazados
				</router-link>
			</li>
		</ul>
		<router-view />
	</div>
</template>
<script type="text/javascript">
	export default {
		mounted(){
			this.getMisVehiculos()
		},
		methods: {
			getMisVehiculos(){
				this.$store.dispatch('getMisVehiculos', {
					isNew: 1
				})
			},
		}
	}
</script>