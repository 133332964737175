<template>
	<form ref="predioForm" v-on:submit.prevent="updatePredio()" class="needs-validation was-validated">
		<div class="card">
			<div class="card-header font-weight-bold">
				<div class="d-flex justify-content-between align-items-center">
					<div>
						Editar predio
					</div>

					<div v-if="user.predio_slug">
						<router-link class="btn btn-link btn-sm" :to="{name:'admin.predios.show', params: {id: user.predio_slug}}" title="Enlace del predio" target="_blank">
							Ver sitio
						</router-link>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-lg-12 mb-3">
						<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden mb-3':true}" name="portada" v-on:fileChange="uploadFilePredio($event, 'portada')" style="height: 100px;">
							<div class="text-center">
								<img v-lazy="$filters.url('https://clubcashin.com/storage/predios/'+form.portada)" class="img-fluid" alt="Agregar portada del predio" title="Portada del predio" v-if="form.portada">
								<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar portada" title="Portada del predio" v-else>
								<p class="font-weight-bold mb-0">Portada</p>
							</div>
						</Upload>
					</div>
					<div class="col-lg-12">
						<div class="form-row">
							<div class="col-lg-3 mb-3">
								<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="logo" v-on:fileChange="uploadFilePredio($event, 'logo')" style="height: 150px;">
									<div class="text-center">
										<img v-lazy="$filters.url('https://clubcashin.com/storage/predios/'+form.logo)" class="img-fluid" alt="Agregar logo del predio" title="Logo del predio" v-if="form.logo">
										<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar logo" title="Logo del predio" v-else>
										<p class="font-weight-bold mb-0">Logo</p>
									</div>
								</Upload>
							</div>

							<div class="col-lg-9">
								<div class="mb-3">
									<label for="nombre" class="mb-0">Nombre:</label>
									<input type="text" id="nombre" name="nombre" v-model="form.nombre" class="form-control bg-light" placeholder="Nombre del predio" maxlength="150" required v-if="user.prf == 3">
									<p v-else>{{form.nombre}}</p>
								</div>

								<div class="mb-3" v-if="user.prf == 3">
									<label for="slug" class="mb-0">Personaliza tu URL:</label>
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text" id="basic-url">{{url}}</span>
										</div>
										<input type="text" id="slug" name="slug" v-model="form.slug" class="form-control bg-light" maxlength="150" aria-describedby="basic-url" placeholder="Personaliza tu URL" @keydown.space.prevent required>
									</div>
									<span v-if="!validateSlug" class="text-danger">
										La url no es válida
									</span>
								</div>
							</div>

							<div class="col-lg-4 mb-3">
								<label for="telefono" class="mb-0">Teléfono:</label>
								<input type="tel" id="telefono" name="telefono" v-model="form.telefono" class="form-control bg-light" placeholder="Número de teléfono" maxlength="150">
							</div>

							<div class="col-lg-4 mb-3">
								<label for="correo" class="mb-0">Correo electrónico:</label>
								<input type="email" id="correo" name="correo" v-model="form.correo" class="form-control bg-light" placeholder="Correo electrónico" maxlength="150">
							</div>

							<div class="col-lg-4 mb-3">
								<label for="interes" class="mb-0">Interés:</label>
								<input type="number" id="interes" name="interes" v-model="form.interes" class="form-control bg-light" placeholder="Interés" min="0" max="100" step="0.5">
							</div>

							<div class="col-lg-5 mb-3" v-if="user.prf == 3">
								<label for="usuario" class="mb-0">Usuario:</label>
								<select id="usuario" class="custom-select" name="usuario" v-model="form.usuario">
									<option v-for="(usuario, index) in usuarios" :key="index" :value="usuario.idusuario">
										{{usuario.mail}}
									</option>
								</select>
							</div>

							<div class="col-lg-12 mb-3">
								<h3>Redes sociales</h3>
								<div class="bg-light p-3 rounded">
									<div class="table-responsive">
										<table class="table table-sm table-borderless">
											<tr v-for="(red, index) in tiposRedes" :key="index">
												<th width="30">
													<font-awesome-icon :icon="['fab', 'facebook']" style="font-size: 25px;" v-if="red.tipo == 'Facebook'" />
													<font-awesome-icon :icon="['fab', 'twitter']" style="font-size: 25px;" v-else-if="red.tipo == 'Twitter'" />
													<font-awesome-icon :icon="['fab', 'youtube']" style="font-size: 25px;" v-else-if="red.tipo == 'YouTube'" />
													<font-awesome-icon :icon="['fab', 'instagram']" style="font-size: 25px;" v-else-if="red.tipo == 'Instagram'" />
													<font-awesome-icon :icon="['fas', 'link']" style="font-size: 25px;" v-else-if="red.tipo == 'Sitio Web'" />
													<span v-else>
														{{red.tipo}}
													</span>
												</th>
												<th>
													<input type="text" :name="toSlug(red.tipo)" v-model="red.link" class="form-control form-control-sm" maxlength="150" :placeholder="red.tipo" @keydown.space.prevent>
													<span v-if="!validateUrl(red.link) && red.link" class="text-danger">
														La url no es válida
													</span>
												</th>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer text-right">
				<button type="submit" class="btn btn-primary mr-3" :disabled="loadingPredios || loadingLogo">
					<span class="material-icons align-bottom">save</span>
					Actualizar
				</button>
				<router-link class="btn btn-link" :to="{name:'admin.predios'}" :disabled="!validateForm || loadingPredios || loadingLogo" v-if="user.prf == 3">
					<span class="material-icons align-bottom">arrow_back</span>
					Volver
				</router-link>
			</div>
		</div>
	</form>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const Upload = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Upload" */"@/components/Upload.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const FontAwesomeIcon = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "fortawesome" */"@fortawesome/vue-fontawesome").then(mod => mod.FontAwesomeIcon),
		loadingComponent: {template:`<loading-component />`}
	})
	export default {
		components: {
			Upload,
			FontAwesomeIcon
		},
		data(){
			return {
				form: {
					nombre: '',
					slug: '',
					telefono: '',
					correo: '',
					logo: '',
					portada: '',
					interes: 0,
					tiposRedes: [],
					usuario: ''
				},
				usuarios: [],
				loadingLogo: false,
				loadingPredios: false,
				validateForm: false,
				validateSlug: true,
				tiposRedes: [
					{
						tipo: 'Facebook',
						link: '',
					},
					{
						tipo: 'Twitter',
						link: '',
					},
					{
						tipo: 'YouTube',
						link: '',
					},
					{
						tipo: 'Instagram',
						link: '',
					},
					{
						tipo: 'Sitio Web',
						link: '',
					},
				],
			}
		},
		mounted(){
			this.setData(this.predio)
			this.getUsuarios()
		},
		methods: {
			updatePredio(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingPredios = true
				this.form.tiposRedes = this.tiposRedes.filter(x => x.link != '')
				this.form.codigo_predio = this.predio.codigo_predio || -1
				this.$store.dispatch('updatePredio', this.form).then(response => {
					window.toastr.info('Predio actualizado exitosamente.', 'Aviso')
					if(this.user.prf == 3){
						this.$store.dispatch('getPredios')
						this.$router.push({name: 'admin.predios'})
					}else{
						this.getUsuarios()
					}
					return response
				}).catch(error =>{
					if (error.response) {
						window.toastr.error(error.response.data.msg, 'Error')
					}else{
						window.toastr.error('Error al actualizar predio', 'Error')
					}
					return error
				}).then(() => {
					this.loadingPredios = false
				}).catch()
			},

			getUsuarios(){
				this.$store.dispatch('getUsuarios').then(res => {
					this.usuarios = (res.data || [])
				}).catch()
			},

			getPredioRedes(val){
				this.$store.dispatch('getPredioRedes', {
					codigo_predio: val
				}).then(res => {
					var redes = res.data || []
					for (var i = redes.length - 1; i >= 0; i--) {
						var data = redes[i] || {}
						var indexObj = this.tiposRedes.findIndex((x => x.tipo == data.tipo))
						this.tiposRedes[indexObj].link = data.link || ''
					}
				}).catch()
			},
			uploadFilePredio(file, tipo){
				this.loadingLogo = true
				var formData = new FormData()
				formData.append('images', file)
		        formData.append('modulo', 'predios')
				this.$store.dispatch('uploadFile', formData).then(response => {
					this.form[tipo] = response.data[0]
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingLogo = false
				})
			},
			
			checkValidity(){
				if (!this.$refs.predioForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.predioForm.checkValidity()
				}
			},
			toSlug(val){
				var nombre = (val.split(' ').join('_') || '').toLowerCase().substring(0, 150)
				nombre = nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
				return nombre
			},
			validateUrl(value) {
				return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
			},
			setData(val){
				this.form.nombre = val.nombre || ''
				this.form.slug = val.slug || ''
				this.form.telefono = val.telefono || ''
				this.form.correo = val.correo || ''
				this.form.logo = val.logo || ''
				this.form.portada = val.portada || ''
				this.form.interes = val.interes || ''

				this.getPredioRedes(val.codigo_predio)
			}
		},
		computed: {
			id(){
				return this.$route.params.id || -1
			},
			nombre(){
				return this.form.nombre
			},
			slug(){
				return this.form.slug
			},
			url(){
				return window.url
			},
			predios(){
				return this.$store.getters.predios || []
			},
			predio(){
				var data = this.predios.filter(x => x.slug == this.id) || []
				if (data.length>0) {
					return data[0]
				}
				return []
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
		},
		watch: {
			nombre(val, oldVal){
				if (this.form.slug == this.toSlug(oldVal)) {
					this.form.slug = this.toSlug(val)
				}
			},

			slug(val){
				this.validateSlug = this.validateUrl(this.url+'/'+val)
			},

			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			},
			'predio':{
				handler(val){
					this.setData(val)
				},
				deep: true
			}
		}
	}
</script>