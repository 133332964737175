<template>
	<div class="card">
		<div class="card-body">
			<Agenda></Agenda>
		</div>
	</div>
</template>
<script type="text/javascript">
	import Agenda from "@/components/Agenda.vue"
	export default {
		components: {
			Agenda
		}
	}
</script>