<template>
	<div>
		<div class="card text-primary mb-3 h-100">
			<div class="card-body">
				<div class="row align-items-stretch justify-content-center">
					<div class="col-lg-4">
						<div class="d-flex align-items-start flex-column h-100">
							<div class="mb-auto">
								<label>Alias:</label>
								<br>
								<strong>{{user.alias}}</strong>
								<br>
								<br>
							</div>
							<div>
								<label>Nombre:</label>
								<br>
								<strong>{{user.nombre}}</strong>
								<br>
								<br>
							</div>
							<div>
								<label>Apellido:</label>
								<br>
								<strong>{{user.apellido}}</strong>
								<br>
								<br>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="d-flex align-items-start flex-column h-100">
							<div class="mb-auto">
								<label>Correo:</label>
								<br>
								<strong>{{user.mail}}</strong>
								<br>
								<br>
							</div>
							<div>
								<label>DPI:</label>
								<br>
								<strong>{{user.dpi}}</strong>
								<br>
								<br>
							</div>
							<div>
								<label>Teléfono:</label>
								<br>
								<strong>{{user.telefono}}</strong>
								<br>
								<br>
							</div>
						</div>
					</div>
				</div>
				<div class="text-right">
					<router-link :to="{name:'logout'}" class="btn btn-danger rounded-pill font-weight-bold px-2">
						<small>Cerrar sesión</small>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	export default {
		data(){
			return {
				loadingProfile: false,
			}
		},
		computed: {
			user(){
				return this.$store.getters.currentUser || {}
			}
		},
	}
</script>