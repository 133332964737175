<template>
	<router-view />
</template>
<script type="text/javascript">
	export default {
		mounted(){
			this.getMarcas()
		},
		methods: {
			getMarcas(){
				this.$store.dispatch('getMarcas', {
					order: 'idMarca desc'
				})
			}
		},
	}
</script>
