<template>
	<router-view />
</template>
<script type="text/javascript">
	export default {
		mounted(){
			this.getModelos()
		},
		methods: {
			getModelos(){
				this.$store.dispatch('getModelos', {
					order: 'idModelo desc'
				})
			}
		},
	}
</script>
