<template>
	<div>
		<ul class="nav nav-tabs flex-column flex-md-row nav-stacked">
			<li class="nav-item">
				<router-link :to="{name:'admin.vehiculos.pendientes'}" class="nav-link" exact-active-class="active">
					Pendientes
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{name:'admin.vehiculos.aprobadas'}" class="nav-link" exact-active-class="active">
					Aprobadas
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{name:'admin.vehiculos.rechazadas'}" class="nav-link" exact-active-class="active">
					Rechazadas
				</router-link>
			</li>
		</ul>
		<router-view />
	</div>
</template>
<script type="text/javascript">
	export default {
		mounted(){
			this.getMisVehiculos()
		},
		methods: {
			getMisVehiculos(){
				this.$store.dispatch('getMisVehiculos', {
					idusuario: this.user.idusuario
				})
			},
		},
		computed: {
			user(){
				return this.$store.getters.currentUser || {}
			},
		},
	}
</script>