<template>
	<button type="button" class="btn btn-link btn-sm" title="Eliminar" v-on:click="deleteLineaNoti()" :disabled="loading">
		<span class="material-icons align-bottom">delete</span>
	</button>
</template>
<script type="text/javascript">
	export default {
		props: ['id'],
		emits: ['getLineas'],
		data(){
			return {
				loading: false
			}
		},
		methods: {
			deleteLineaNoti(){
				var mod = this
				$.confirm({
					theme: 'modern',
					title: '¡Confirmación!',
					columnClass: 'col-lg-6',
					content: `¿Estás seguro que deseas eliminar la línea?`,
					buttons: {
						si: function () {
							mod.deleteLinea()
				    	},
				    	no: function () {

				    	},
				    }
				})
			},
			deleteLinea(){
				var id = this.id
				this.loading = true
				this.$store.dispatch('deleteLinea', {idLinea: id}).then(res => {
					window.toastr.success("Se ha eliminado la línea.", "Éxito")
					this.$emit('getLineas')
					return res
				}).catch(err => {
					window.toastr.error('No se pudo eliminar la línea, intenta de nuevo.', 'Error')
					return err
				}).then(() => {
					this.loading = false
				})
			}
		}
	}
</script>