<template>
	<div class="container-fluid">
		<div class="row justify-content-center py-3 bg-inversion" style="min-height: calc(100vh - 165px);">
			<div class="col-md-11">
				<div class="row align-items-stretch">
					<div class="col-lg-3 mb-3">
						<div class="card">
							<div class="card-body">
								<div class="bg-primary rounded pt-4 px-3 mx-auto" style="width: 100px;">
									<img src="@/assets/image/usuarios.webp" alt="Solicitante" class="img-fluid">
								</div>
								<h4 class="text-primary font-weight-bold text-center">{{user.nomperf}}</h4>

								<div class="text-center mb-3 border-bottom border-top border-primary py-2" v-if="user.predio != null">
									<router-link class="btn btn-primary font-weight-bold btn-block" :to="{name:'vehiculos.create'}">
										Publicar
									</router-link>
								</div>

								<div class="list-group" v-if="user.predio != null">
									<router-link :to="{name:'admin.index'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" exact-active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">account_circle</i>
										<div>
											Perfil
										</div>
									</router-link>
									<router-link :to="{name:'admin.predios.edit', params: {id: user.predio_slug}}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">commute</i>
										<div>
											Info
										</div>
									</router-link>
									<router-link :to="{name:'admin.vehiculos.pendientes'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">directions_car</i>
										<div>
											Vehículos
										</div>
									</router-link>
								</div>
								<div class="list-group" v-else>
									<router-link :to="{name:'admin.index'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" exact-active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">account_circle</i>
										<div>
											Perfil
										</div>
									</router-link>
									<router-link :to="{name:'admin.agenda'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">event</i>
										<div>
											Agenda
										</div>
									</router-link>
									<router-link :to="{name:'admin.vehiculos.pendientes'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">directions_car</i>
										<div>
											Vehículos
										</div>
									</router-link>
									<router-link :to="{name:'admin.vehiculos-nuevos'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">electric_car</i>
										<div>
											Vehículos nuevos
										</div>
									</router-link>
									<router-link :to="{name:'admin.apartados.pendientes'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<span class="material-icons">car_rental</span>
										<div>
											Vehículos apartados
										</div>
									</router-link>
									<router-link :to="{name:'admin.predios'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<span class="material-icons">commute</span>
										<div>
											Predios
										</div>
									</router-link>
									<router-link :to="{name:'admin.marcas'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<span class="material-icons">car_repair</span>
										<div>
											Marcas
										</div>
									</router-link>
									<router-link :to="{name:'admin.modelos'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<span class="material-icons">rv_hookup</span>
										<div>
											Modelos
										</div>
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-9 mb-3">
						<router-view />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	export default {
		mounted(){
			this.$store.commit('footerBG', 'bg-inversion')
		},
		methods:{
			getUser(){
				this.$store.dispatch('getCurrentUser').then(response => {
					return response
				}).catch(error =>{
					return error
				})
			},
		},
		computed: {
			user(){
				return this.$store.getters.currentUser || {}
			},
		},
		watch: {
			'user': {
				handler(val) {
					if(val.prf==2){
						this.$store.commit('footerBG', 'bg-inversion')
					}else{
						this.$store.commit('footerBG', 'bg-credito')
					}
				},
				deep: true
			}
		}
	}
</script>