<template>
	<div class="container-fluid">
		<div class="row justify-content-center bg-secondary-light py-5" style="min-height: calc(100vh - 164px)">
			<div class="mb-3 col-lg-8">
				<div class="card">
					<div class="card-header bg-primary text-white">
						<div class="text-right">
							<router-link :to="{name:'admin.apartados.pendientes'}" v-if="prenda.estadoApartado == 0">
								<img src="@/assets/image/close_white.webp" style="width: 20px; height: 20px;">
							</router-link>
							<router-link :to="{name:'admin.apartados.aprobadas'}" v-if="prenda.estadoApartado == 1">
								<img src="@/assets/image/close_white.webp" style="width: 20px; height: 20px;">
							</router-link>
							<router-link :to="{name:'admin.apartados.rechazadas'}" v-if="prenda.estadoApartado == 2 || prenda.estadoApartado == 3">
								<img src="@/assets/image/close_white.webp" style="width: 20px; height: 20px;">
							</router-link>
						</div>
					</div>
					<div class="card-body py-4">
						<div class="row">
							<div class="col-lg-4 mb-3">
								<div class="h-100 d-flex align-items-center justify-content-center px-3">
									<div class="w-100">
										<div class="px-4 pt-4 bg-primary overflow-hidden rounded mb-2" style="border-radius: 5px;">
											<img src="@/assets/image/usuarios.webp" class="img-fluid">
										</div>
										<h5 class="text-center font-weight-bold">
											{{prenda.nombre}} {{prenda.apellido}}
										</h5>
										<h5 class="text-center font-weight-bold">
											{{prenda.alias}}
										</h5>
										<div class="mb-2">
											<strong>Correo:</strong>
											<br>
											{{prenda.mail}}
										</div>
										<div class="mb-2">
											<strong>DPI:</strong>
											<br>
											{{prenda.dpi}}
										</div>
										<div class="mb-2">
											<strong>Teléfono:</strong>
											<br>
											{{prenda.telefono}}
										</div>
										<div class="mb-2">
											<strong>Fecha Nacimiento:</strong>
											<br>
											{{prenda.fechaNacimiento}}
										</div>
										<div class="mb-2">
											<strong>Perfil:</strong>
											<br>
											{{prenda.idperfil == 1 ? 'Solicitante' : 'Inversionista'}}
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-8 mb-3">
								<div class="card bg-light">
									<div class="card-body">
										<div class="d-flex flex-column flex-lg-row justify-content-between">
											<div class="order-1 order-lg-0 mb-3">
												<h5 class="font-weight-bold d-flex justify-content-between">
													<router-link :to="{name:'vehiculos.show', params: {id: prenda.idVehiculo}}">
														{{prenda.marca}} {{prenda.modelo}} {{prenda.ano}}
													</router-link>
													<span class="badge badge-primary" v-if="prenda.estadoApartado==0">
														Pendiente
													</span>
													<span class="badge badge-success" v-if="prenda.estadoApartado==1">
														Aprobado
													</span>
													<span class="badge badge-danger" v-if="prenda.estadoApartado==2 || prenda.estadoApartado==3">
														Rechazado
													</span>
												</h5>
												<h3>
													{{$filters.currency(prenda.precio)}}
												</h3>
												<div class="mb-1" v-if="prenda.tipo">
													<b>Tipo: </b> {{prenda.tipo}}
												</div>
												<div class="mb-1" v-if="prenda.color">
													<b>Color: </b> {{prenda.color}}
												</div>
												<div class="mb-1" v-if="prenda.modelo">
													<b>Número de pasajeros: </b> {{prenda.numeroPasajeros}}
												</div>

												<div class="form-row border rounded mb-3">
													<div class="col-4 border-right">
														<div class="media pt-1 pl-1">
															<span class="material-icons mr-1">event</span>
															<div class="media-body">
																<p class="m-0"><small class="m-0 text-primary font-weight-bold">Año:</small></p>
																<small>{{prenda.ano}}</small>
															</div>
														</div>
													</div>
													<div class="col-4 border-right">
														<div class="media pt-1 pl-1">
															<span class="material-icons mr-1">speed</span>
															<div class="media-body">
																<p class="m-0"><small class="m-0 text-primary font-weight-bold">Km:</small></p>
																<small>{{$filters.currency(prenda.kilometraje, false)}}</small>
															</div>
														</div>
													</div>
													<div class="col-4">
														<div class="media pt-1 pl-1">
															<img src="@/assets/image/transmision.svg" class="mr-1 img-fluid" alt="transmision" style="height: 24px;">
															<div class="media-body">
																<p class="m-0"><small class="m-0 text-primary font-weight-bold">Transmisión:</small></p>
																<small>
																	{{prenda.desTransmision}}
																</small>
															</div>
														</div>
													</div>
													<div class="col-12 border-top"></div>
													<div class="col-4 border-right">
														<div class="media pt-1 pl-1">
															<span class="material-icons mr-1">local_gas_station</span>
															<div class="media-body">
																<p class="m-0"><small class="m-0 text-primary font-weight-bold">Combustible:</small></p>
																<small>
																	{{prenda.combustible}}
																</small>
															</div>
														</div>
													</div>
													<div class="col-4 border-right">
														<div class="media pt-1 pl-1">
															<span class="material-icons">car_repair</span>
															<div class="media-body">
																<p class="m-0"><small class="m-0 text-primary font-weight-bold">Motor:</small></p>
																<small>
																	{{prenda.motor}}
																</small>
															</div>
														</div>
													</div>
													<div class="col-4">
														<div class="media pt-1 pl-1">
															<span class="material-icons mr-1">emoji_transportation</span>
															<div class="media-body">
																<p class="m-0"><small class="m-0 text-primary font-weight-bold">Origen:</small></p>
																<small>{{prenda.desOrigen}}</small>
															</div>
														</div>
													</div>
												</div>

												<div class="card bg-light">
													<h2>Detalles</h2>
													<div class="text-justify white-space card-body">{{prenda.descripcionVehiculo}}</div>
												</div>
												<br>
											</div>	
											<div class="order-0 order-lg-1 mx-auto mx-lg-0 mb-3">
												<div style="width: 100px;" class="text-center">
													<img v-lazy="$filters.storage(prenda.ruta, 100)" class="rounded-circle" width="100" height="100" :alt="prenda.nombre" style="object-fit: cover; object-position: center;">
													<br>
													<small class="font-weight-bold" v-if="prenda.idusuario == user.idusuario">
														{{prenda.estado}}
													</small>
												</div>
											</div>
										</div>
										<div class="text-justify white-space mt-3">{{prenda.descripcion}}</div>
										<br>
										<div v-if="prenda.estadoApartado == 0" class="text-right">
											<button v-if="user.prf==3" v-on:click="validateUpdate(1)" class="btn btn-success rounded-pill font-weight-bold px-3 mr-3" :disabled="loading">
												<BtnLoading v-if="loadingAutorizar" />
												<span v-else>
													<span class="material-icons align-bottom">check</span>
													Autorizar
												</span>
											</button>
											<button v-if="user.prf==3" v-on:click="validateUpdate(2)" class="btn btn-danger rounded-pill font-weight-bold px-3 mr-3" :disabled="loading">
												<BtnLoading v-if="loadingRechazar" />
												<span v-else>
													<span class="material-icons align-bottom">close</span>
													Rechazar
												</span>
											</button>
										</div>
										<div v-if="prenda.estadoApartado==1" class="text-right">
											<button v-if="user.prf==3" v-on:click="validateUpdate(3)" class="btn btn-primary rounded-pill font-weight-bold px-3 mr-3" :disabled="loading">
												<BtnLoading v-if="loadingDisponible" />
												<span v-else>
													Colocar como disponible
												</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	export default {
		components: {
			BtnLoading
		},
		data(){
			return {
				loadingPublicacion: false,
				loadingAutorizar: false,
				loadingRechazar: false,
				loadingDisponible: false,
				loading: false,
				prenda: []
			}
		},
		mounted(){
			this.getApartarVehiculos()
		},
		methods:{
			getApartarVehiculos(){
				this.loadingPublicacion = true
				this.$store.dispatch('getApartarVehiculos',{
					idApartado: this.id
				}).then(response => {
					var data = response.data || []
					this.prenda = data[0] || {}
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPublicacion = false
				})
			},
			validateUpdate(estado){
				var mod = this
				var estadoDesc = ''
				if (estado==1) {
					estadoDesc = 'autorizar'
				}
				if (estado==2) {
					estadoDesc = 'rechazar'
				}
				if (estado==3) {
					estadoDesc = 'colocar como disponible'
				}
				$.confirm({
					theme: 'modern',
					title: '¡Confirmación!',
					columnClass: 'col-lg-6',
					content: `¿Estás seguro que deseas ${estadoDesc} esta solicitud?`,
					buttons: {
						si: function () {
							mod.updateApartarVehiculos(estado)
				    	},
				    	no: function () {

				    	},
				    }
				})
			},
			updateApartarVehiculos(estado){
				this.loading = true
				if (estado==1) {
					this.loadingAutorizar = true
				}
				if (estado==2) {
					this.loadingRechazar = true
				}
				if (estado==3) {
					this.loadingDisponible = true
				}
				
				this.$store.dispatch('updateApartarVehiculos', {
					idApartado: this.id,
					estado: estado
				}).then(response => {
					window.toastr.success("Cambio realizado con éxito.", "Éxito")
					this.$router.back()
					return response
				}).catch(error =>{
					window.toastr.error('No se pudo completar el cambio, intenta de nuevo.', 'Error')
					return error
				}).then(() => {
					this.loading = false
					this.loadingAutorizar = false
					this.loadingRechazar = false
					this.loadingDisponible = false
				})
			},
		},
		computed:{
			id(){
				return this.$route.params.id || -1
			},
			isLoggedIn(){
				return this.$store.getters.isLoggedIn
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
		},
	}
</script>