<template>
	<router-view />
</template>
<script type="text/javascript">
	export default {
		mounted(){
			this.getPredios()
		},
		methods: {
			getPredios(){
				this.$store.dispatch('getPredios')
			}
		},
	}
</script>
