<template>
	<form ref="modeloForm" v-on:submit.prevent="addModeloForm()" class="needs-validation was-validated">
		<div class="card">
			<div class="card-header font-weight-bold">
				Agregar modelo
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-lg-12 mb-3">
						<label for="nombre" class="mb-0">Descripción:</label>
						<input type="text" id="nombre" name="descripcion" v-model="form.descripcion" class="form-control bg-light" placeholder="Descripción" maxlength="50" required>
					</div>

					<div class="col-lg-12 mb-3">
						<label for="marca">Marca:</label>
						<Marcas v-model="form.idMarca" />
					</div>
				</div>
			</div>
			<div class="card-footer text-right">
				<button type="submit" class="btn btn-primary mr-3" :disabled="loadingModelos">
					<span class="material-icons align-bottom">save</span>
					Guardar
				</button>
				<router-link class="btn btn-link" :to="{name:'admin.modelos'}" :disabled="!validateForm || loadingModelos">
					<span class="material-icons align-bottom">arrow_back</span>
					Volver
				</router-link>
			</div>
		</div>
	</form>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	const Marcas = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CatalogosMarcas" */"@/components/catalogos/Marcas.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	export default {
		data(){
			return {
				form: {
					descripcion: '',
					idMarca: ''
				},
				loadingModelos: false,
				validateForm: false
			}
		},
		components: {
			Marcas
		},
		methods: {
			addModeloForm(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingModelos = true
				this.$store.dispatch('addModelo', this.form).then(response => {
					window.toastr.info('Modelo agregado exitosamente.', 'Aviso')
					this.$store.dispatch('getModelos', {
						order: 'idModelo desc'
					})
					this.$router.push({name: 'admin.modelos'})
					return response
				}).catch(error =>{
					if (error.response) {
						window.toastr.error(error.response.data.msg, 'Error')
					}else{
						window.toastr.error('Error al registrar modelo', 'Error')
					}
					return error
				}).then(() => {
					this.loadingModelos = false
				})
			},
			checkValidity(){
				if (!this.$refs.modeloForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.modeloForm.checkValidity()
				}
			}
		},
		computed: {
			nombre(){
				return this.form.nombre
			},
			slug(){
				return this.form.slug
			},
			url(){
				return window.url
			}
		},
		watch: {
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		}
	}
</script>